import React from "react";
import styled from "styled-components";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export interface FormItemWrapProps {
  label: string;
  tooltip?: string | null;
  required: boolean;
  styles?: React.CSSProperties;
}

export interface FormItemWrapState {}

class FormItemWrap extends React.Component<
  FormItemWrapProps,
  FormItemWrapState
> {
  public static defaultProps ={
    required:false
  }
  constructor(props: FormItemWrapProps) {
    super(props);
    this.state = {};
  }
  render() {
    const { label, styles,tooltip,required } = this.props;
    return (
      <Wrap>
        <TitleWrap style={styles} >
          {required?<Required/>:null}
          <span>{label}</span>
          {tooltip && (
            <Tooltip title={tooltip}>
              <QuestionCircleOutlined size={14} color="#00000073" />
            </Tooltip>
          )}
        </TitleWrap>
        {this.props.children}
      </Wrap>
    );
  }
}

export default FormItemWrap;
const Wrap = styled.div``;

const TitleWrap = styled.div`
  /* position: relative; */
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;

  span{
    margin-right:8px;
  }
`;

const Required = styled.div`
::before{
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content:  "*";
  }
`
