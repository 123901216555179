import React from 'react';
import {ConfigProvider} from 'antd'
import zhCN from 'antd/lib/locale/zh_CN';
import 'antd/dist/antd.css';

import './App.css';
import Routes from './route/Routes'
class App extends React.Component{

  render() {
    return (
      <ConfigProvider locale={zhCN}>
          <Routes />
      </ConfigProvider>
    )
  }
}

export default App;
