export const uriConfig = [
  {
    title: '基础管理系统',
    subTitle: '基础管理项目，学生，老师，专业，小程序配置等',
    img: 'https://gw.alipayobjects.com/zos/rmsportal/uMfMFlvUuceEyPpotzlq.png',
    uri: '/setting'
  }, { 
    title: '考勤打卡系统',
    subTitle:'教师和学生考勤打卡查询管理系统',
    img:'https://zos.alipayobjects.com/rmsportal/chnhazooyzrjWSv.jpg',
    uri:'/aeolos'
  },{
    title: '防疫数据管理',
    subTitle: '学生每日体温数据，教师每日上报数据',
    img: 'https://gw.alipayobjects.com/zos/rmsportal/iZBVOIhGJiAnhplqjvZW.png',
    uri: '/shield'
  },
  {
    title: '访客管理',
    subTitle: '访客数据信息查询，审批',
    img: 'https://gw.alipayobjects.com/zos/rmsportal/uMfMFlvUuceEyPpotzlq.png',
    uri: '/visitor'
  }, {
    title: '勤工俭学管理系统',
    subTitle: '勤工俭学管理系统',
    img: 'https://gw.alipayobjects.com/zos/rmsportal/iZBVOIhGJiAnhplqjvZW.png',
    uri: '/school_work'
  }, {
    title: '宿舍管理系统',
    subTitle: '宿舍管理系统，学生宿舍管理',
    img: 'https://zos.alipayobjects.com/rmsportal/chnhazooyzrjWSv.jpg',
    uri: '/dormitory'
  }
]