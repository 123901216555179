let server_uri = window.location.origin;

if (
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost'
) {
  // server_uri = 'http://localhost'
   // 测试服务器
  // server_uri = 'https://zeus.net.dadashe.cn'

  // 正式（测试中）
  // server_uri ='https://zeus.test.dadashe.cn'  

    // 开发服务器
  server_uri = 'https://shuai.net.dadashe.cn'
}

const basicConfig = {
  title: '总后台',
  api: server_uri + '/graphql',
  api_url: 'https://dadashe-car.oss-cn-zhangjiakou.aliyuncs.com',  // 上传图片
  apiUpdateUrl : `https://car.cdn.dadashe.cn/`, // 展示图片
  // apiUpdateUrl : `https://dadashe-car.oss-cn-zhangjiakou.aliyuncs.com/`, // 展示图片
  workbench: server_uri,     // 总后台 zeus
  schoolName:'',
  schoolPic:'',
  // school_work: server_uri + "/school_work", // 学工系统 Aphrodite
  // setting: server_uri + "/setting",        // 人员管理、组织结构 Athena 
}


export default basicConfig

export const api_url =  `https://dadashe-car.oss-cn-zhangjiakou.aliyuncs.com` 



/** 备份
 * 
 * const basicConfig = {
  title: '总后台',
  api: 'https://zeus.net.shijianhuoban.com/graphql',
  workbench:'http://workbench.test.mengmengshe.com',     // 总后台 zeus
  school_work:"http://school_work.test.mengmengshe.com", // 学工系统 Aphrodite
  setting: "http://setting.test.mengmengshe.com",        // 人员管理、组织结构 Athena 
}

 */