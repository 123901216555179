import React from "react";
import { BrowserRouter,  Route, Switch } from "react-router-dom";

import RouteInterceptor from "../component/RouteInterceptor";
// import { checkIsSameToken } from "../lib/CrossStorage";

const BasicRoute: React.FC = (props) => {
  // checkIsSameToken()
  return (
    <BrowserRouter>
      <Switch>
      {/* <ApolloProvider client={client}> */}
        <Route
          path="/"
          render={({ history, location, match, }) => {
            return (
              <RouteInterceptor
                history={history}
                location={location}
                match={match}
              />
            );
          }}
        />
        {/* </ApolloProvider> */}
        {/* <RouteInterceptor /> */}
        {/* <Route exact path="/login" component={Login}></Route>
        <BaseLayout>
          <Switch>
            <Route exact path="/" render={() => {
              return <MainContent/>
            }} />
            <Route exact path="/config" component={ConfigPage} />
            <Route exact path="/404" component={NoFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </BaseLayout> */}
      </Switch>
    </BrowserRouter>
  );
};

export default BasicRoute;
