import { Button, Form, Input } from "antd";
import React from "react";
import styled from "styled-components";
import FormItemWrap from "../../../../component/FormItemWrap";

export interface FormContentProps {}

export interface FormContentState {}

class FormContent extends React.Component<FormContentProps, FormContentState> {
  constructor(props: FormContentProps) {
    super(props);
    this.state = {};
  }

  public onFinish = (values:any) => {
    console.log('Success:', values);
  };

  public onFinishFailed = (errorInfo:any) => {
    console.log('Failed:', errorInfo);
  };

  public  normFile = (e:any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    return (
      <FormWrap>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItemWrap label="标题" tooltip="设置标题" required={true}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>
          </FormItemWrap>
          <FormItemWrap label="项目地址链接" tooltip="设置项目地址链接" required={true}>
            <Form.Item
              name="url"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>
          </FormItemWrap>

          <FormItemWrap label="总后台的地址" tooltip="设置总后台的地址" required={true}>
            <Form.Item
              name="address"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>
          </FormItemWrap>

          <FormItemWrap label="api 服务地址" tooltip="设置api 服务地址" required={true}>
            <Form.Item
              name="apiUrl"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>
          </FormItemWrap>

          <FormItemWrap label="请求地址" tooltip="设置请求地址" required={true}>
            <Form.Item
              name="resUrl"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>
          </FormItemWrap>

          {/* <FormItemWrap label="图标" tooltip="请上传图标" required={true}>
          <Form.Item
            name="upload"
            // label="Upload"
            valuePropName="fileList"
            getValueFromEvent={this.normFile}
            extra="这是上传图片提示"
          >
            <Upload name="logo" action="/upload.do" listType="picture">
              <Button icon={<UploadOutlined />}>上传图标</Button>
            </Upload>
          </Form.Item>
          </FormItemWrap> */}

          <Form.Item >
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </FormWrap>
    );
  }
}

// const FormContentWrap = Form.create<Props>()(FormContent);

export default FormContent;

const FormWrap = styled.div`
  width: 380px;
  .ant-form-item{
    margin-bottom: 16px;
  }
`
