import { isBefore } from "date-fns";
import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import routes from "../../config/routes";
import BaseLayout from "../../layout/Layout";
import Login from "../../pages/Login";

export interface RouteInterceptorProps extends RouteComponentProps {}

export interface RouteInterceptorState {
  isLogin: boolean
  // loading: boolean
}

class RouteInterceptor extends React.Component<
  RouteInterceptorProps,
  RouteInterceptorState
> {
  constructor(props: RouteInterceptorProps) {
    super(props);
    this.state = {
      isLogin: false,
      // loading: true
    };
  }

  render() {
    const { location } = this.props;
    const { pathname,search } = location;
    // const { search } = location.;
    // const {loading, isLogin} = this.state

    const currentPage = routes.find((v) => v.path === pathname);

    //  如果路由匹配
    if (currentPage) {
        const token = localStorage.getItem("token")
        const expiresTime = localStorage.getItem('expiresTime');
        let isExpires;
        if (expiresTime) {
          isExpires = isBefore(new Date(), new Date(Number(expiresTime)));
        }
      
        const isLogin = isExpires && token;
        // 判断是否已经登录，未登录跳到登录页

        // if (loading) {
        //   return <LoadingContent>
        //       <Spin size='large' />
        //   </LoadingContent>
        // } else 

         //  如果是退出登录页面 （包括其他模块的退出到总后台的登录页面）
          if (search.includes('redirect')) {
            return <Route location={location} exact={false} component={Login} />
          }

        if (isLogin) {
          if (pathname === "/login") {
            return <Redirect to="/" />;
          } else {
            // 跳转到相应的路由
              return (
                <BaseLayout>
                  <Route path={pathname} component={currentPage.component}  />
                </BaseLayout>
              );
          }
        }else {
          // 如果是登录页面， 直接跳转到登录页面
          if (currentPage.name === 'login') {
            return <Route path={pathname} component={currentPage.component} />
          }
          // 如果页面需要登录后才能进入的页面，跳转到登录页面
          if (currentPage.auth  ) {
            return <Redirect to="/login" />
          } else {
            // 不需要登录也能进入的页面，就直接进入
            return (
              <BaseLayout>
                <Route path={pathname} component={currentPage.component} />
              </BaseLayout>
            );
          }
        }
      // }
    } else {
      // 路由不匹配跳到 404
      return <Redirect to="/404" />;
    }
  }
}

export default RouteInterceptor;