import { Col, Progress, Row } from 'antd';
import { FC } from 'react';
import './index.scss';
interface IPwdStrengthProps {
  pwdStrength: 0 | 1 | 2 | 3;
}
const PwdStrength: FC<IPwdStrengthProps> = ({ pwdStrength }) => {
  return (
    <div className='passwordStrongBox'>
      <Row gutter={12}>
        <span className='passWord'>密码强度</span>
        <Col span={3}>
          <Progress className='weak' percent={pwdStrength > 0 ? 100 : 0} strokeColor={pwdStrength > 0?'#ff4d4f':''} showInfo={false} />
        </Col>
        <Col span={3}>
          <Progress className='middle' percent={pwdStrength > 1 ? 100 : 0} strokeColor={pwdStrength > 1?'#e4ce2b':''} showInfo={false} />
        </Col>
        <Col span={3}>
          <Progress className='strong' percent={pwdStrength > 2 ? 100 : 0} strokeColor={pwdStrength > 2?'#52c41a':''} showInfo={false} />
        </Col>
        <span className="passStrong">
          {pwdStrength === 1 && '弱'}
          {pwdStrength === 2 && '中'}
          {pwdStrength === 3 && '强'}
        </span>
      </Row>
    </div>
  );
};
export default PwdStrength;