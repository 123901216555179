import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { addSeconds } from "date-fns";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { LoginDocument } from "../../apollo";
import client from "../../apollo/client";
import FormItemWrap from "../../component/FormItemWrap";
import { message } from "../../component/message";
import { getPageQuery } from "../../lib";

import loginBg from '../../image/loginBg4.png';

export interface LoginProps extends RouteComponentProps {}

export interface LoginState {
  loading: boolean;
}

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount(){
    console.log('版本号：1.0.0')
  }

  public onFinish = async (values: any) => {
    const { mobile, password } = values;

    this.setState({ loading: true });

    await client
      .mutate({
        mutation: LoginDocument,
        variables: {
          LoginByAdmin: {
            mobile,
            password,
          },
        },
      })
      .then(({ data: loginByAdmin }) => {
        this.setState({ loading: false });
        const {
          token,
          expires_in,
          // permissions,
          // roles,
          user_id,
        } = loginByAdmin.loginByAdmin;
        const result = addSeconds(new Date(), expires_in);
        const expiresTime = JSON.stringify(new Date(result).valueOf());

        localStorage.setItem("token", token);
        localStorage.setItem("expiresTime", expiresTime);
        // localStorage.setItem("permissions", permissions);
        // localStorage.setItem("roles", roles);
        localStorage.setItem("user_id", user_id);

        const { history } = this.props;

        const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        message.success("🎉 🎉 🎉  登录成功！");
        let { redirect } = params as { redirect: string };
        if (redirect) {
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.origin === urlParams.origin) {
            redirect = redirect.substr(urlParams.origin.length);
            if (redirect.match(/^\/.*#/)) {
              redirect = redirect.substr(redirect.indexOf("#") + 1);
            }
          } else {
            window.location.href = "/";
            return;
          }
        }

        if (redirect) {
          window.open(redirect, "_self");
        } else {
          history.replace("/");
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        message.error(e);
      });
  };

  render() {
    const { loading } = this.state;
    return (
      <LoginCotent style={{backgroundImage: `url(${loginBg})`}}>
        {/* <Image src={loginBg} /> */}
        
        <FormWrap>
          <h1>后台管理系统</h1>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
          >
            <FormItemWrap label="用户名" required={true}>
              <Form.Item
                name="mobile"
                rules={[{ required: true, message: "请输入用户名!" }]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined style={{ color: "#1890ff" }} />}
                />
              </Form.Item>
            </FormItemWrap>

            <FormItemWrap label="密码" required={true}>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "请输入密码!" }]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined style={{ color: "#1890ff" }} />}
                />
              </Form.Item>
            </FormItemWrap>

            <Form.Item {...{ offset: 8, span: 16 }}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </FormWrap>
      </LoginCotent>
    );
  }
}

export default Login;

const LoginCotent = styled.div`
  position: relative ;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  padding: 24px 0 100px;
  /* overflow-y:scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  /* background:url('../../image/loginBg.png') no-repeat; */
  /* background: no-repeat center/80% url(loginBg);  */
  /* background-color: #fff; */
`;

const FormWrap = styled.div`
  position: absolute; 
  top: 150px;
  right: 200px;
  padding: 50px 0;
  width: 350px;

  & > h1 {
    font-size: 33px;
    text-align: center;
    margin-bottom: 30px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
`;
