import { Button, Result } from 'antd';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps{}

class NoFoundPage extends React.Component<Props>{

  render() {
    return(
      <Result
        status="404"
        title="404"
        subTitle="您访问的页面不存在"
        extra={
          <Button type="primary">
            <Link to='/'>返回</Link>
          </Button>
        }
      />)
  }
}


// const NoFoundPage: React.FC<Props> = () => {
    
 
// )};

export default NoFoundPage;
