/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import styled from 'styled-components';
import { NavigatorType } from '../../Type';
import { client } from '../../../../apollo/client';
import {  CreateQuickUrlDocument, CreateQuickUrlMutation, CreateQuickUrlMutationVariables, UpdateQuickUrlDocument, UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables } from '../../../../apollo';

interface SetWifiProps {
  visible: boolean;
  listItem: any;
  onCancel: () => void;
  onRefetch: () => void;
}

const SetNaviagtor: React.FC<SetWifiProps> = (props) => {
  const [loading, setLoading]: [boolean, any] = useState(false);

  const {
    visible,
    onCancel,
    onRefetch,
    listItem: { id, uri, name },
  } = props;

  const onFinish = (values: NavigatorType) => {
    setLoading(true);
    if (props.listItem && props.listItem.id) {
      client.mutate<UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables>({
        mutation:UpdateQuickUrlDocument,
        variables: { 
          UpdateQuickUrl:{id,...values}
        }
      }).then(v => {
        onRefetch()
        onCancel();
        setLoading(false)
      })
    } else {
      client.mutate<CreateQuickUrlMutation, CreateQuickUrlMutationVariables>({
        mutation:CreateQuickUrlDocument,
        variables: { 
          CreateQuickUrl:{...values}
        }
      }).then(v => {
        onRefetch()
        onCancel();
        setLoading(false)
      })
    }
    // setWifi({ id, ...values }).then(() => {
    //   onRefetch();
    //   onCancel();
    //   setLoading(false)
    // });
  };

  return (
    <Modal
      destroyOnClose
      title="快捷导航"
      visible={visible}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Form
        name="basic"
        initialValues={{ uri, name }}
        onFinish={onFinish}
        style={{ maxHeight: 400, overflow: 'scroll' }}
      >
        <Form.Item name="name" label='名称' rules={[{ required: true, message: '当前字段不可为空' }]}>
          <Input placeholder="请输入" />
        </Form.Item>

        <Form.Item name="uri" label='链接' rules={[{type: 'url', required: true, message: '请输入正确格式的链接。例如： "https://www.baidu.com"', }]}>
          <Input placeholder="请输入正确格式的链接" />
        </Form.Item>

        <BtnWrap>
          <Button style={{ marginRight: 20 }} onClick={onCancel}>
            取消
          </Button>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              确定
            </Button>
          </Form.Item>
        </BtnWrap>
      </Form>
    </Modal>
  );
};

export default SetNaviagtor;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;