import {
  ChangeAvatarDocument,
  ChangeAvatarMutation,
  ChangeAvatarMutationVariables,
} from "../../apollo";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, message, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import client from "../../apollo/client";
import basicConfig, { api_url } from "../../config/basicConfig";

import "./index.scss";
import { signOssUpload } from "../../lib";

const AvatarView: React.FC<AvatarProps> = (props) => {
  // const {avatar} = props;
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const img = localStorage.getItem("avatarImg") || "";
    setImg(img);
  }, []);

  const onFinish = (name: any) => {
    setLoading(true);
    // let fileList = [...info.fileList];

    //  fileList.forEach((file) => {
    // if (file.response) {
    // 如果需要展示上传后的文件名则放开
    // file.name = file.response.file_name
    client
      .mutate<ChangeAvatarMutation, ChangeAvatarMutationVariables>({
        mutation: ChangeAvatarDocument,
        variables: { avatar: name },
      })
      .then((v) => {
        setLoading(false);
        message.success("上传头像成功");
        const avatar = v.data?.changeAvatar.avatar
          ? v.data?.changeAvatar.avatar
          : "";
        setImg(avatar || "");

        localStorage.setItem("avatarImg", avatar);
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        message.error("上传失败，请重试");
      });
    // }
    // });
  };

  return (
    <Spin tip="正在上传..." spinning={loading}>
      <div className="avatar">
        <Avatar
          size={114}
          style={{ backgroundColor: "#87d068" }}
          icon={<UserOutlined />}
          src={img ? basicConfig.apiUpdateUrl + img : undefined}
        />
      </div>
      <Upload
        action={`${basicConfig.api_url}/api/file/upload/avatar`}
        maxCount={1}
        accept="image/jpeg"
        // onChange={handleChange}
        showUploadList={false}
        customRequest={async ({ data, onSuccess, file }) => {
          const fileName = file as any;
          signOssUpload(fileName.name)
            .then(async (d) => {
              if (d.accessKeyId) {
                setLoading(true);
                const formData = new FormData();

                formData.append("key", d.key);
                formData.append("policy", d.policy);
                formData.append("OSSAccessKeyId", d.access_key_id);
                formData.append("signature", d.signature);
                formData.append("success_action_status", "200");
                formData.append("file", file);

                await fetch(api_url, { method: "POST", body: formData })
                  .then((res) => {
                    onFinish(d.key);
                  })
                  .catch((err) => {
                    message.error(err);
                    setLoading(false);
                  });
              }
            })
            .catch((e) => {
              setLoading(false);
            });
        }}
      >
        <div className="button_view">
          <Button>
            <UploadOutlined />
            更换头像
          </Button>
        </div>
      </Upload>
    </Spin>
  );
};

export default AvatarView;

interface AvatarProps {
  // avatar: string
}
