import React from "react";
import "../App.css";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import Menu from "antd/lib/menu";
import { Link } from "react-router-dom";
import {
  AppstoreOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

// import qinghua from '../image/qinghua.jpeg'
import qinghua from "../image/school.png";
import { Avatar, Dropdown } from "antd";
import client from "../apollo/client";
import { ViewerDocument, ViewerQuery, ViewerQueryVariables } from "../apollo";
import { message } from "../component/message";
import basicConfig from "../config/basicConfig";

interface State {
  menuSelectKey: string[];
  name: string | null | undefined;
  headimgurl: string | null | undefined;
}

class BaseLayout extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      menuSelectKey: this.initDefault(),
      name: "",
      headimgurl: "",
    };
  }

  public initDefault = () => {
    const url = window.location.hash;
    if (url.includes("config")) {
      return ["2"];
    }
    return ["1"];
  };

  public renderMenu = () => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to="/accout">个人中心</Link>
        </Menu.Item>
        <Menu.Item key="1" onClick={this.onLoginOut}>
          {/* <Link to='/login' replace>退出</Link> */}
          退出
        </Menu.Item>
      </Menu>
    );
  };

  componentDidMount() {
    client
      .query<ViewerQuery, ViewerQueryVariables>({
        query: ViewerDocument,
        fetchPolicy: "network-only",
      })
      .then((v) => {
        if (v.data?.viewer) {
          const { nickname, headimgurl, avatar } = v.data.viewer;

          const avatarImg = avatar ? avatar : headimgurl ? headimgurl : "";

          localStorage.setItem("viwer", JSON.stringify(v.data.viewer));
          localStorage.setItem("avatarImg", avatarImg);
          // localStorage.setItem('roles',JSON.stringify(roles))
          // localStorage.setItem('permissions',JSON.stringify(permissions))

          this.setState({ name: nickname, headimgurl: avatar || headimgurl });
        }
      })
      .catch((e) => {
        message.error(e);
      });
  }

  userInfoPage = () => {};

  public onLoginOut = () => {
    // 清除本地 key 清除本地token
    localStorage.clear();
    // removeLocalStorage('key').then(() => {
    window.location.replace("/login");
    // });
  };

  returnEl = () => {
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child: any) => React.cloneElement(child, { doSomething: "----000000" })
    );
    return <div>{childrenWithProps}</div>;
  };

  render() {
    const { menuSelectKey, name, headimgurl } = this.state;
    return (
      <Layout className="layout" style={{ height: "100vh" }}>
        <Header>
          <HeadeItemWrap>
            <Link to="/" replace={true}>
              <LogoWrap>
                <LogoPicWrap>
                  <img src={qinghua} alt="logo" />
                </LogoPicWrap>
                <h3>合肥城市学院</h3>
              </LogoWrap>
            </Link>
            <MenuWrap>
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={menuSelectKey}
              >
                <Menu.Item key="1" icon={<AppstoreOutlined />}>
                  <Link to="/">首页</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<SettingOutlined />}>
                  <Link to="/config">配置</Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Dropdown
                    overlay={this.renderMenu}
                    placement="bottomCenter"
                    arrow
                  >
                    <UserStatusWrap>
                      <Avatar
                        style={{ backgroundColor: "#87d068", marginRight: 10 }}
                        icon={<UserOutlined />}
                        src={
                          headimgurl
                            ? basicConfig.apiUpdateUrl + headimgurl
                            : undefined
                        }
                      />
                      <span>{name || "角色名"}</span>
                    </UserStatusWrap>
                  </Dropdown>
                </Menu.Item>
              </Menu>
            </MenuWrap>
          </HeadeItemWrap>
        </Header>
        {/* <Content style={{ padding: '24px 24px' , minHeight:'100%', overflowY: 'scroll'}}> */}
        <Content
          style={{
            minHeight: "100%",
            overflowY: "scroll",
            paddingBottom: "50px",
          }}
        >
          {this.props.children}
          {/* {
            React.Children.map(this.props.children, (child:any) => {
              return React.cloneElement(child, child.props, React.Children.map(child.props.children, child => {
                return React.cloneElement(child, { params: 'test' });
              }));
            })
          } */}
          {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
        </Content>
      </Layout>
    );
  }
}
export default BaseLayout;

const MenuWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LogoWrap = styled.div`
  display: flex;
  color: #fff;
  align-items: center;

  & > h3 {
    margin: 0;
    margin-left: 8px;
    color: #fff;
  }
`;

const LogoPicWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    vertical-align: inherit;
    width: 100%;
  }
`;

const HeadeItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserStatusWrap = styled.div`
  display: flex;
  /* width:83px; */
  justify-content: space-between;
  align-items: center;
`;
