import { ProFormText } from "@ant-design/pro-form";
import React, { useEffect, useState } from "react";
import passwordChange from "./PasswordChange";
import PwdStrength from "./PwdStrength";


interface Props {
  setPasswordLevel: (level: number) => void;
}

const PasswordLevel: React.FC<Props> = (props) => {
  const [newPswLevel, setNewPswLevel]: [0 | 1 | 2 | 3, any] = useState(0);
  const { setPasswordLevel } = props

  useEffect(()=> {
    setPasswordLevel(newPswLevel)
  },[newPswLevel,setPasswordLevel])

  return (
    <ProFormText.Password
      key='password_level'
      placeholder="请输入新密码"
      rules={[
        {
          required: true,
          message: "请输入新密码",
        },
      ]}
      name="password"
      label="新密码"
      help={<PwdStrength pwdStrength={newPswLevel} />}
      fieldProps={{
        onChange: (e) => {
          const level = passwordChange(e.target.value);
          setNewPswLevel(level);
        },
      }}
    />
  );
};


export default PasswordLevel