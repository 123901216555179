const pattern_1 = /^.*([0-9])+.*$/i; //数字
const pattern_2 = /[a-z]/; //小写字母
const pattern_3 = /[A-Z]/; //大写字母
const pattern_4 = /[`~!@#$%^&*()\-_+=\\|{}':;",[\].<>/?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、？]/; //特殊字符

const passwordChange = (pwd:string) => {
  let level = 0;
  if (pwd) {
    pwd = pwd.trim();
    if (pwd.length >= 6) {
      if (pattern_1.test(pwd)) {
        level++;
      }
      if (pwd.length > 10) {
        level++;
      }
      if (pattern_2.test(pwd) || pattern_3.test(pwd)) {
        level++;
      }
      if (pattern_4.test(pwd)) {
        level++;
      }
      if (level > 3) {
        level = 3;
      }
    }
  }

  return level as 0 | 1 | 2 | 3;
}

export default passwordChange