import message from "antd/lib/message";
import { parse } from "querystring";
import { ViewerDocument, ViewerQuery, ViewerQueryVariables } from "../apollo";
import client from "../apollo/client";

export const getPageQuery = () => parse(window.location.href.split('?')[1]);


export const getViewer = () => {
 return client.query<ViewerQuery, ViewerQueryVariables>({
    query: ViewerDocument,
    fetchPolicy: 'network-only'
  }).then(v => {
    if (v.data?.viewer) {
      const { headimgurl,  avatar } = v.data.viewer;

      const avatarImg = avatar ? avatar : headimgurl ? headimgurl : '';

      localStorage.setItem('viwer', JSON.stringify(v.data.viewer));
      localStorage.setItem('avatarImg',avatarImg)
      // localStorage.setItem('roles',JSON.stringify(roles))
      // localStorage.setItem('permissions',JSON.stringify(permissions))

      // this.setState({name: nickname,headimgurl: avatar || headimgurl})
    }

    return v.data.viewer
  }).catch(e => {
    message.error(e)
    return e;
  })
}


// oss 上传签名
// export const signOssUpload = async (file_name: string) => {
//   return client.mutate({
//     mutation: RequestUploadOssFileDocument,
//     variables: { file_name },
//   })


// oss 上传签名 api
export const signOssUpload = async (file_name: string) => {
  return fetch(`https://zeus.net.dadashe.cn/api/file/sign/oss?file_name=${file_name}`)
  .then(response => response.json())
}