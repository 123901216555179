import {
  ChangePasswordByOldPasswordDocument,
  ChangePasswordByOldPasswordMutation,
  ChangePasswordByOldPasswordMutationVariables,
} from "../../apollo";

import { FormOutlined } from "@ant-design/icons";
import { ProFormText } from "@ant-design/pro-form";
import { Card, Form, Input, Modal, message as Atmessage } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";

import styled from "styled-components";
import AvatarView from "./AvatarView";

import { message } from "../../component/message";
import client from "../../apollo/client";
import PasswordLevel from "../../component/PassWordLevel";

const AccountMgmt: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPswLevel, setNewPswLevel]: [0 | 1 | 2 | 3, any] = useState(0);


  const [form] = useForm();

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values: any) => {

    if (newPswLevel < 3) {
      Modal.warning({
        title: '提醒',
        content: '当前设置密码级别太低，请重新设置密码（密码级别最低为级别 3 ）',
      });
      return
    }

    setLoading(true);
    client
      .mutate<
        ChangePasswordByOldPasswordMutation,
        ChangePasswordByOldPasswordMutationVariables
      >({
        mutation: ChangePasswordByOldPasswordDocument,
        variables: {
          ChangePassword: {
            old_password: values.old_password,
            password: values.password,
          },
        },
      })
      .then((v) => {
        setLoading(false);
        Atmessage.success("密码修改成功");
        form.resetFields();
        setVisible(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error(e);
      });
  };

  return (
    <Card>
      <AvatarWrap>
        <div>
          <AvatarView />
        </div>
      </AvatarWrap>
      <InfoWrap>
        <ItemWrap>
          <div>登录密码：</div>
          <div>*************</div>
          <EditWrap onClick={() => setVisible(true)}>
            <FormOutlined style={{ color: "#1990ff", fontSize: "18px" }} />
            <p>修改密码</p>
          </EditWrap>
        </ItemWrap>
      </InfoWrap>

      <Modal
        title="修改密码"
        key="accoutmgmt"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading }}
        destroyOnClose
      >
        <Form
          form={form}
          name="change_password"
          onFinish={onFinish}
        >
          <Form.Item
            label="原密码"
            name="old_password"
            rules={[{ required: true, message: "请输入原密码!" }]}
          >
            <Input.Password placeholder="请输入原密码" />
          </Form.Item>

          <PasswordLevel  setPasswordLevel={(level) => setNewPswLevel(level)}/>
          <div style={{ height: 10 }}></div>
          <ProFormText.Password
            label="确认密码"
            name="reSetPassword"
            placeholder="请输入确认密码"
            rules={[
              {
                required: true,
                message: "请输入确认密码",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("两次密码输入不一致!"));
                },
              }),
            ]}
          />
        </Form>
      </Modal>
    </Card>
  );
};

export default AccountMgmt;

const AvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
`;

const InfoWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0 80px 50px;
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;

  > div:nth-child(1) {
    color: #000;
  }
`;

const EditWrap = styled.div`
  margin-left: 20px;
  text-align: center;
`;
