// import { Image } from "antd";
import {
  AntDesignOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card, Empty } from "antd";
import Meta from "antd/lib/card/Meta";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import styled from "styled-components";
import {
  DeleteQuickUrlDocument,
  DeleteQuickUrlMutation,
  DeleteQuickUrlMutationVariables,
  QuickUrlsComponent,
} from "../../apollo";
import client from "../../apollo/client";
import basicConfig from "../../config/basicConfig";
import { getViewer } from "../../lib";
import SetNaviagtor from "./Components/CreateNavigation";
import { uriConfig } from "./config.uri";

import "./index.css";
// import { ViewerComponent } from "../../apollo";
// import qinghua from '../../image/qinghua.jpeg'

interface Props extends RouteComponentProps {}

interface State {
  nid: string;
  visible: boolean;
  nListItem: any;
  isEdit: boolean;
  deleteId: string;
  avatarImg: string | null | undefined;
  name: string | null | undefined;
}

class MainContent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nid: "",
      visible: false,
      nListItem: {},
      isEdit: false,
      deleteId: "",
      avatarImg: "",
      name: "",
    };
  }

  public onRefetch: any;

  componentDidMount() {
    const view = localStorage.getItem("viwer");

    if (view) {
      const v = JSON.parse(view);
      this.setState({
        avatarImg: v?.avatar || v?.headimgurl,
        name: v?.nickname,
      });
    } else {
      getViewer().then((v) => {
        this.setState({
          avatarImg: v?.avatar || v?.headimgurl,
          name: v?.nickname,
        });
      });
    }
  }

  jumpTo = (url: string) => {
    window.location.pathname = url;
  };

  onCancel = () => {
    this.setState({
      visible: false,
      nid: "",
      nListItem: {},
      isEdit: false,
    });
  };

  onCreateNavigation = () => {
    this.setState({
      visible: true,
      nid: "",
      nListItem: {},
      isEdit: false,
    });
  };

  setEdiltStatus = (e: any) => {
    this.setState((pre) => ({
      isEdit: !pre.isEdit,
    }));
  };

  clickNavigatorCard = (uri: string) => {
    window.open(uri);
  };

  deleteQuickUrlFun = (id: string) => {
    this.setState({ deleteId: id });
    client
      .mutate<DeleteQuickUrlMutation, DeleteQuickUrlMutationVariables>({
        mutation: DeleteQuickUrlDocument,
        variables: { id },
      })
      .then((v) => {
        this.onRefetch();
        this.setState({ deleteId: "" });
      })
      .catch(() => {
        this.setState({ deleteId: "" });
      });
  };

  render() {
    const {  visible, nListItem, isEdit, deleteId, avatarImg, name } =
      this.state;
    return (
      <MainContentWrap>
        <HeaderContent>
          <LeftPart>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 72, xxl: 100 }}
              icon={<AntDesignOutlined />}
              src={avatarImg ? basicConfig.apiUpdateUrl + avatarImg : undefined}
            />
            <div className="person_sub">
              <div className="person_sub_top">
                你好， {name || "老师"} 祝你开心每一天
              </div>
              <div className="person_sub_btm"></div>
            </div>
          </LeftPart>
        </HeaderContent>

        <MainBtmContent>
          <Row
            justify="space-between"
            gutter={{ xs: 8, sm: 16, md: 24 }}
            style={{ width: "100%" }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              {/* <Card title="项目菜单" extra={<a href="#">全部项目</a>}> */}
              <Card title="项目菜单">
                <Row gutter={[24, 24]}>
                  {uriConfig.map((v) => {
                    return (
                      <Col span={8} key={v.title}>
                        <Card
                          className="mainCard"
                          hoverable
                          onClick={this.jumpTo.bind(this, v.uri)}
                          cover={<img alt={v.title} src={v.img}></img>}
                        >
                          <Meta
                            title={v.title}
                            description={v.subTitle}
                            style={mateStyle}
                          />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <QuickUrlsComponent
                notifyOnNetworkStatusChange
                fetchPolicy="network-only"
              >
                {({ data, loading, error, refetch, networkStatus }) => {
                  this.onRefetch = refetch;

                  if (error) {
                    return null;
                  }

                  const list = data?.quickUrls || [];

                  return (
                    <Card
                      title="快速开始/便捷导航"
                      loading={loading && networkStatus === 1}
                      extra={
                        <RightPartTitleBtnWrap>
                          <Button
                            type="link"
                            block
                            onClick={this.onCreateNavigation}
                          >
                            添加
                          </Button>
                          <Button
                            type="link"
                            block
                            danger
                            onClick={this.setEdiltStatus}
                          >
                            {isEdit ? "取消" : "设置"}
                          </Button>
                        </RightPartTitleBtnWrap>
                      }
                    >
                      {!list.length ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      ) : (
                        <Row gutter={[8, 8]}>
                          {list.map((v) => {
                            return (
                              <Col span={6} key={v.id}>
                                <Card
                                  hoverable
                                  className="mainCard"
                                  style={{ padding: 0 }}
                                  onClick={this.clickNavigatorCard.bind(
                                    this,
                                    v.uri
                                  )}
                                  actions={
                                    isEdit
                                      ? [
                                          <EditOutlined
                                            key="edit"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.setState({
                                                visible: true,
                                                nListItem: v,
                                                nid: v.id,
                                              });
                                            }}
                                          />,
                                          deleteId === v.id ? (
                                            <LoadingOutlined key="loading" />
                                          ) : (
                                            <DeleteOutlined
                                              key="ellipsis"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                this.deleteQuickUrlFun(v.id);
                                              }}
                                            />
                                          ),
                                        ]
                                      : []
                                  }
                                >
                                  <SpanWrap>{v.name}</SpanWrap>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      )}
                    </Card>
                  );
                }}
              </QuickUrlsComponent>
            </Col>
          </Row>
        </MainBtmContent>
        <SetNaviagtor
          visible={visible}
          listItem={nListItem}
          onRefetch={this.onRefetch}
          onCancel={this.onCancel}
        />
      </MainContentWrap>
    );
  }
}

export default MainContent;

const MainContentWrap = styled.div`
  width: 100%;
  min-height: 100%;
  padding-bottom: 36px;
  /* display:flex; */
  /* padding: 0 24px; */
  /* align-items:center; */
  /* background-color: #fff; */

  .mainCard {
    cursor: pointer;
  }
`;

const RightPartTitleBtnWrap = styled.div`
  display: flex;

  .ant-btn {
    padding: 0 4px;
  }
`;

const HeaderContent = styled.div`
  width: 100%;
  padding: 36px 24px 24px;
  background-color: #fff;
`;

const LeftPart = styled.div`
  display: flex;
  align-items: center;
`;

const MainBtmContent = styled.div`
  margin: 24px 0 0 24px;
  display: flex;
`;

const mateStyle = {
  height: "75px",
  overflow: "hidden",
};

const SpanWrap = styled.span`
  display: block;
  width: 100%;
  text-align: center;
`;

// const gridStyle = {
//   border: 0,
// };
