import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
// import { ApolloProvider } from '@apollo/react-hooks';
import client from "./apollo/client";
import { ApolloProvider } from "react-apollo";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://009ff7455b9f44e79c288cbeb600a86e@error.net.dadashe.cn/2",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
