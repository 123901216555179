import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Implement the DateTime<Local> scalar
   *
   * The input/output is a string in RFC3339 format.
   */
  DateTime: any;
  /**
   * ISO 8601 calendar date without timezone.
   * Format: %Y-%m-%d
   *
   * # Examples
   *
   * * `1994-11-13`
   * * `2000-02-24`
   */
  NaiveDate: any;
  /**
   * ISO 8601 combined date and time without timezone.
   *
   * # Examples
   *
   * * `2015-07-01T08:59:60.123`,
   */
  NaiveDateTime: any;
};

/** 录取规则 */
export type AdmissionRule = {
  __typename?: 'AdmissionRule';
  id: Scalars['String'];
  content: Scalars['String'];
};

/** 校友 */
export type Alumni = {
  __typename?: 'Alumni';
  id: Scalars['String'];
  true_name: Scalars['String'];
  /** 封面图 */
  cover: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  is_show: Scalars['Boolean'];
  created_date: Scalars['NaiveDateTime'];
};

export type AlumniFilter = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<AlumniFilterOrder>;
  is_show?: Maybe<Scalars['Boolean']>;
  true_name?: Maybe<Scalars['String']>;
};

export type AlumniFilterOrder = {
  created_date?: Maybe<Scalars['String']>;
};

export type AlumniList = {
  __typename?: 'AlumniList';
  count: Scalars['Int'];
  list: Array<Alumni>;
};

export type ApproveLeaveReview = {
  id: Scalars['String'];
  /** 状态 通过 拒绝 */
  status: Scalars['String'];
  /** 拒绝理由 */
  refuse_reason?: Maybe<Scalars['String']>;
};

export type ApproveTask = {
  id: Scalars['String'];
  /** 审核状态 通过 未通过 */
  approve_status: Scalars['String'];
  /** 未通过理由 */
  reason?: Maybe<Scalars['String']>;
};

export type AssignTask = {
  id: Scalars['String'];
  /** 执行者 id */
  executor_user_id: Scalars['String'];
};

export type ChangePassword = {
  old_password: Scalars['String'];
  password: Scalars['String'];
};

/** 打卡 */
export type ClockIn = {
  __typename?: 'ClockIn';
  id: Scalars['String'];
  /** 精度 */
  lat: Scalars['Float'];
  /** 纬度 */
  lng: Scalars['Float'];
  /** 原因 */
  reason?: Maybe<Scalars['String']>;
  /** 地址 */
  address: Scalars['String'];
  /** 证明人 */
  witness?: Maybe<Scalars['String']>;
  /** 日期 */
  date: Scalars['NaiveDate'];
  /** 证明人手机号 */
  witness_mobile?: Maybe<Scalars['String']>;
  /** 照片 */
  photo?: Maybe<Scalars['String']>;
  created_date: Scalars['NaiveDateTime'];
  student?: Maybe<StudentPublic>;
};

/** 学院 */
export type College = {
  __typename?: 'College';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  faculties: Array<Faculty>;
};

export type CollegePublic = {
  __typename?: 'CollegePublic';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** 常见问题 */
export type CommonProblem = {
  __typename?: 'CommonProblem';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 内容 */
  content?: Maybe<Scalars['String']>;
};

export type CommonProblemFilter = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<CommonProblemFilterOrder>;
};

export type CommonProblemFilterOrder = {
  created_date?: Maybe<Scalars['String']>;
};

export type CommonProblemList = {
  __typename?: 'CommonProblemList';
  count: Scalars['Int'];
  list: Array<CommonProblem>;
};

export type CreateClockIn = {
  ev: Scalars['String'];
  address?: Maybe<Scalars['String']>;
};

export type CreateClockOther = {
  ev: Scalars['String'];
  reason: Scalars['String'];
  witness: Scalars['String'];
  witness_mobile: Scalars['String'];
  photo: Scalars['String'];
  address?: Maybe<Scalars['String']>;
};

export type CreateLeave = {
  student_id: Scalars['String'];
  start_date: Scalars['NaiveDateTime'];
  end_date: Scalars['NaiveDateTime'];
  reason: Scalars['String'];
  data_type?: Maybe<Scalars['String']>;
  is_leave: Scalars['Boolean'];
};

export type CreateNewReader = {
  new_id: Scalars['String'];
};

export type CreateQuickUrl = {
  name: Scalars['String'];
  uri: Scalars['String'];
};

export type CreateStudentLeave = {
  start_date: Scalars['NaiveDateTime'];
  end_date: Scalars['NaiveDateTime'];
  reason: Scalars['String'];
  data_type?: Maybe<Scalars['String']>;
  is_leave: Scalars['Boolean'];
};


/** 部门 */
export type Department = {
  __typename?: 'Department';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 排序 */
  sort_number?: Maybe<Scalars['Int']>;
  /** 父级 id */
  father_id?: Maybe<Scalars['String']>;
};

export type DepartmentPublic = {
  __typename?: 'DepartmentPublic';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** 用人申请 */
export type EmploymentApplication = {
  __typename?: 'EmploymentApplication';
  id: Scalars['String'];
  /** 项目名称 */
  name: Scalars['String'];
  /** 工作内容 */
  content: Scalars['String'];
  /** 类型：固定岗/临时岗 */
  data_type: Scalars['String'];
  /** 工作地点 */
  address: Scalars['String'];
  /** 人员数量 */
  people_count: Scalars['Int'];
  /** 上班时间 2020-01-01 格式 */
  start_date: Scalars['NaiveDateTime'];
  /** 状态 待审核 通过 拒绝 */
  status: Scalars['String'];
  /** 拒绝理由 */
  reason?: Maybe<Scalars['String']>;
  created_date: Scalars['NaiveDateTime'];
  teacher?: Maybe<TeacherPublic>;
  department?: Maybe<DepartmentPublic>;
};

export type EmploymentApplicationFilter = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  department_id?: Maybe<Scalars['String']>;
  department_ids?: Maybe<Array<Scalars['String']>>;
  columns?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<EmploymentApplicationFilterOrder>;
};

export type EmploymentApplicationFilterOrder = {
  created_date?: Maybe<Scalars['String']>;
};

export type EmploymentApplicationList = {
  __typename?: 'EmploymentApplicationList';
  count: Scalars['Int'];
  list: Array<EmploymentApplication>;
};

/** 系 */
export type Faculty = {
  __typename?: 'Faculty';
  id: Scalars['String'];
  name: Scalars['String'];
  college: CollegePublic;
  teacher?: Maybe<TeacherPublic>;
};

export type FinishTask = {
  id: Scalars['String'];
  /** 开始时间 */
  start_date: Scalars['NaiveDateTime'];
  /** 结束时间 */
  end_date: Scalars['NaiveDateTime'];
};

/** 奖励政策 */
export type IncentivePolicies = {
  __typename?: 'IncentivePolicies';
  id: Scalars['String'];
  /** 内容 */
  content?: Maybe<Scalars['String']>;
};

/** 院系专业介绍 */
export type Introduction = {
  __typename?: 'Introduction';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 内容 */
  content?: Maybe<Scalars['String']>;
};

/** 请假记录 */
export type Leave = {
  __typename?: 'Leave';
  id: Scalars['String'];
  /** 开始时间 */
  start_date: Scalars['NaiveDateTime'];
  /** 结束时间 */
  end_date: Scalars['NaiveDateTime'];
  /** 姓名 */
  true_name: Scalars['String'];
  /** 请假原因 */
  reason: Scalars['String'];
  /** 院 */
  college: Scalars['String'];
  /** 系 */
  faculty: Scalars['String'];
  /** 专业 */
  major: Scalars['String'];
  /** 年级 */
  grade: Scalars['Int'];
  /** 班级 */
  class_name?: Maybe<Scalars['Int']>;
  /** 请假天数 */
  day_count: Scalars['Int'];
  /** 请假类型 */
  data_type?: Maybe<Scalars['String']>;
  /** 是否离校 */
  is_leave: Scalars['Boolean'];
  /** 手机号 */
  mobile: Scalars['String'];
  /** 状态 审核中 审核通过 已销假 已取消 已拒绝 */
  status: Scalars['String'];
  /** 拒绝原因 */
  refuse_reason?: Maybe<Scalars['String']>;
  owner: StudentPublic;
  leaveRecord: LeaveRecordPublic;
  /** 审批记录 */
  reviews: Array<LeaveReview>;
};

export type LeavePublic = {
  __typename?: 'LeavePublic';
  id: Scalars['String'];
  true_name?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};

export type LeaveRecordPublic = {
  __typename?: 'LeaveRecordPublic';
  id: Scalars['String'];
  leaveId: Scalars['String'];
  userId: Scalars['String'];
  teacherId: Scalars['String'];
};

/** 审批记录 */
export type LeaveReview = {
  __typename?: 'LeaveReview';
  id: Scalars['String'];
  /** 状态 审批中 拒绝 通过 */
  status: Scalars['String'];
  /** 拒绝原因 */
  refuse_reason?: Maybe<Scalars['String']>;
  teacher?: Maybe<TeacherPublic>;
  leave?: Maybe<LeavePublic>;
};

export type LoginByAdmin = {
  mobile: Scalars['String'];
  password: Scalars['String'];
};

export type Matriculate = {
  id: Scalars['String'];
  is_matriculate: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 创建打卡 */
  createClockIn: ClockIn;
  /** 创建打卡 */
  createClockOther: ClockIn;
  /** 创建请假记录 */
  createLeave: Leave;
  createLeaveByStudent: Leave;
  /** 取消请假 */
  cancelLeave: Leave;
  /** 销假 */
  finishLeave: Leave;
  /** 审批请假 */
  approveLeaveReview: LeaveReview;
  /** 创建新闻阅读者 */
  createNewReader: NewReader;
  /** 创建快捷操作 */
  createQuickUrl: QuickRrl;
  /** 更新快捷操作 */
  updateQuickUrl: QuickRrl;
  /** 删除快捷操作 */
  deleteQuickUrl: QuickRrl;
  /** 录取 */
  matriculate: SignUp;
  /** 指派任务 */
  assignTask: Task;
  /** 完成任务 */
  finishTask: Task;
  /** 完成任务 */
  approveTask: Task;
  loginByAdmin: UserAccessToken;
  loginTeacherWeChat: UserAccessToken;
  changePasswordByOldPassword: User;
  changeAvatar: User;
};


export type MutationCreateClockInArgs = {
  CreateClockIn: CreateClockIn;
};


export type MutationCreateClockOtherArgs = {
  CreateClockOther: CreateClockOther;
};


export type MutationCreateLeaveArgs = {
  CreateLeave: CreateLeave;
};


export type MutationCreateLeaveByStudentArgs = {
  CreateStudentLeave: CreateStudentLeave;
};


export type MutationCancelLeaveArgs = {
  UpdateLeave: UpdateLeave;
};


export type MutationFinishLeaveArgs = {
  UpdateLeave: UpdateLeave;
};


export type MutationApproveLeaveReviewArgs = {
  ApproveLeaveReview: ApproveLeaveReview;
};


export type MutationCreateNewReaderArgs = {
  CreateNewReader: CreateNewReader;
};


export type MutationCreateQuickUrlArgs = {
  CreateQuickUrl: CreateQuickUrl;
};


export type MutationUpdateQuickUrlArgs = {
  UpdateQuickUrl: UpdateQuickUrl;
};


export type MutationDeleteQuickUrlArgs = {
  id: Scalars['String'];
};


export type MutationMatriculateArgs = {
  Matriculate: Matriculate;
};


export type MutationAssignTaskArgs = {
  AssignTask: AssignTask;
};


export type MutationFinishTaskArgs = {
  FinishTask: FinishTask;
};


export type MutationApproveTaskArgs = {
  ApproveTask: ApproveTask;
};


export type MutationLoginByAdminArgs = {
  LoginByAdmin: LoginByAdmin;
};


export type MutationLoginTeacherWeChatArgs = {
  WeChatLogin: WeChatLogin;
};


export type MutationChangePasswordByOldPasswordArgs = {
  ChangePassword: ChangePassword;
};


export type MutationChangeAvatarArgs = {
  avatar: Scalars['String'];
};



/** 新闻 */
export type New = {
  __typename?: 'New';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 类型 */
  data_type: Scalars['String'];
  /** 是否热点 */
  is_hot: Scalars['Boolean'];
  /** 封面图 */
  cover: Scalars['String'];
  /** 内容 */
  content?: Maybe<Scalars['String']>;
  /** 是否显示 */
  is_show: Scalars['Boolean'];
  readerCount: Scalars['Int'];
};

/** 新闻阅读者 */
export type NewReader = {
  __typename?: 'NewReader';
  id: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** 用人申请列表 */
  employmentApplicationListPublic: EmploymentApplicationList;
  /** 小程序查看奖励政策详情 */
  getIncentivePolicyOne?: Maybe<IncentivePolicies>;
  /** 查看学校概况详情 */
  getSchoolOverviewTabs: Array<SchoolOverview>;
  /** 获取学校概况类型 */
  getSchoolOverviewTypes: Array<Scalars['String']>;
  /** 查看审批记录 */
  getSchoolOverviewById: SchoolOverview;
  /** 学校概况列表 */
  schoolOverviewPublicList: SchoolOverviewList;
  /** 查看录取规则详情 */
  getAdmissionRuleFirst: AdmissionRule;
  /** 小程序校友分页列表 */
  alumniPublicList: AlumniList;
  colleges: Array<College>;
  getCommonProblems: Array<CommonProblem>;
  /** 常见问题列表 */
  commonPublicProblemList: CommonProblemList;
  /** 获取所有的部门 */
  departments: Array<Department>;
  /** 查询系 */
  faculties: Array<Faculty>;
  /** 查看历年分数省份 */
  getHistoryScoreProvinces: Array<Scalars['String']>;
  /** 查看历年分数年份 */
  getHistoryScoreYears: Array<Scalars['String']>;
  /** 查看历年分数文史类型 */
  getHistoryScoreNames: Array<Scalars['String']>;
  /** 小程序通过名字查看院系专业介绍详情 */
  getIntroductionByName?: Maybe<Introduction>;
  /** 查看新闻 */
  getNewById: New;
  /** 获取新闻的类型 */
  getNewTypes: Array<Scalars['String']>;
  /** 查询快捷操作 */
  quickUrls: Array<QuickRrl>;
  /** 查看校园风光类型 */
  getSchoolPhotoByType: Array<SchoolPhoto>;
  /** 查看校园风光类型 */
  getSchoolPhotoTypes: Array<Scalars['String']>;
  /** 小程序校园风光分页列表 */
  schoolPublicPhotoList: SchoolPhotoList;
  /** 查看自己的报名详情 */
  getNewByOwner?: Maybe<SignUp>;
  /** 查看总结 */
  getSummaryById: Summary;
  /** 总结列表 */
  ownSummaryList: SummaryList;
  users: Array<User>;
  viewer: User;
  /** 小程序查询视频 */
  getVideos: Array<Video>;
};


export type QueryEmploymentApplicationListPublicArgs = {
  filter: EmploymentApplicationFilter;
};


export type QueryGetSchoolOverviewByIdArgs = {
  id: Scalars['String'];
};


export type QuerySchoolOverviewPublicListArgs = {
  filter: SchoolOverviewFilter;
};


export type QueryAlumniPublicListArgs = {
  filter: AlumniFilter;
};


export type QueryCommonPublicProblemListArgs = {
  filter: CommonProblemFilter;
};


export type QueryFacultiesArgs = {
  college_id: Scalars['String'];
};


export type QueryGetIntroductionByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetNewByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetSchoolPhotoByTypeArgs = {
  type: Scalars['String'];
};


export type QuerySchoolPublicPhotoListArgs = {
  filter: SchoolPhotoFilter;
};


export type QueryGetSummaryByIdArgs = {
  id: Scalars['String'];
};


export type QueryOwnSummaryListArgs = {
  filter: SummaryFilter;
};

/** 快捷操作 */
export type QuickRrl = {
  __typename?: 'QuickRrl';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 链接 */
  uri: Scalars['String'];
};

/** 学校概况 */
export type SchoolOverview = {
  __typename?: 'SchoolOverview';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 封面 */
  cover?: Maybe<Scalars['String']>;
  /** 类型 */
  data_type: Scalars['String'];
  /** 内容 */
  content?: Maybe<Scalars['String']>;
};

export type SchoolOverviewFilter = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  data_type?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<SchoolOverviewFilterOrder>;
};

export type SchoolOverviewFilterOrder = {
  created_date?: Maybe<Scalars['String']>;
};

export type SchoolOverviewList = {
  __typename?: 'SchoolOverviewList';
  count: Scalars['Int'];
  list: Array<SchoolOverview>;
};

/** 校园风光 */
export type SchoolPhoto = {
  __typename?: 'SchoolPhoto';
  id: Scalars['String'];
  /** 类型 */
  data_type: Scalars['String'];
  /** 照片 */
  photo: Scalars['String'];
};

export type SchoolPhotoFilter = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<SchoolPhotoFilterOrder>;
};

export type SchoolPhotoFilterOrder = {
  created_date?: Maybe<Scalars['String']>;
};

export type SchoolPhotoList = {
  __typename?: 'SchoolPhotoList';
  count: Scalars['Int'];
  list: Array<SchoolPhoto>;
};

/** 报名 */
export type SignUp = {
  __typename?: 'SignUp';
  id: Scalars['String'];
  /** 姓名 */
  true_name: Scalars['String'];
  /** 性别 */
  sex: Scalars['String'];
  /** 手机号 */
  mobile: Scalars['String'];
  /** 科类 文科 理科 综合 艺术 体育 */
  categorie: Scalars['String'];
  /** 拟报专业 */
  profession: Scalars['String'];
  /** 省 */
  province: Scalars['String'];
  /** 市 */
  city: Scalars['String'];
  /** 区 */
  area: Scalars['String'];
  /** 分数 */
  score: Scalars['Int'];
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  /** 是否录取 */
  is_matriculate?: Maybe<Scalars['Boolean']>;
};

export type StudentPublic = {
  __typename?: 'StudentPublic';
  id: Scalars['String'];
  trueName: Scalars['String'];
  mobile: Scalars['String'];
};

/** 总结 */
export type Summary = {
  __typename?: 'Summary';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 总结类型 日 月 年 */
  date_type: Scalars['String'];
  /** 年 */
  year: Scalars['String'];
  /** 月 */
  month: Scalars['String'];
  /** 日 */
  day: Scalars['String'];
  /** 内容 */
  content?: Maybe<Scalars['String']>;
};

export type SummaryFilter = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<SummaryFilterOrder>;
};

export type SummaryFilterOrder = {
  created_date?: Maybe<Scalars['String']>;
};

export type SummaryList = {
  __typename?: 'SummaryList';
  count: Scalars['Int'];
  list: Array<Summary>;
};

/** 任务 */
export type Task = {
  __typename?: 'Task';
  id: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 开始时间 */
  start_date: Scalars['NaiveDateTime'];
  /** 结束时间 */
  end_date: Scalars['NaiveDateTime'];
  /** 内容 */
  content?: Maybe<Scalars['String']>;
  /** 状态 */
  status: Scalars['String'];
  /** 审核状态 */
  approve_status: Scalars['String'];
  /** 未通过理由 */
  reason?: Maybe<Scalars['String']>;
  /** 执行者 id */
  executor_user_id?: Maybe<Scalars['String']>;
  /** 完成者 id */
  finish_user_id?: Maybe<Scalars['String']>;
};

export type TeacherPublic = {
  __typename?: 'TeacherPublic';
  id: Scalars['String'];
  trueName: Scalars['String'];
  departmentId: Scalars['String'];
  mobile: Scalars['String'];
};

export type UpdateLeave = {
  id: Scalars['String'];
  student_id: Scalars['String'];
  start_date: Scalars['NaiveDateTime'];
  end_date: Scalars['NaiveDateTime'];
  reason: Scalars['String'];
  data_type?: Maybe<Scalars['String']>;
  is_leave: Scalars['Boolean'];
};

export type UpdateQuickUrl = {
  id: Scalars['String'];
  name: Scalars['String'];
  uri: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  sex?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  headimgurl?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  proxy_headimgurl?: Maybe<Scalars['String']>;
};

export type UserAccessToken = {
  __typename?: 'UserAccessToken';
  user_id: Scalars['String'];
  token: Scalars['String'];
  student_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['String']>;
  created_date: Scalars['DateTime'];
  expires_in: Scalars['Int'];
};

/** 视频 */
export type Video = {
  __typename?: 'Video';
  id: Scalars['String'];
  /** 视频标题 */
  name: Scalars['String'];
  /** 视频文件名 */
  filename: Scalars['String'];
};

export type WeChatLogin = {
  wx_code: Scalars['String'];
  encrypted_data: Scalars['String'];
  iv: Scalars['String'];
};

export type ChangeAvatarMutationVariables = Exact<{
  avatar: Scalars['String'];
}>;


export type ChangeAvatarMutation = (
  { __typename?: 'Mutation' }
  & { changeAvatar: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'headimgurl' | 'avatar'>
  ) }
);

export type ChangePasswordByOldPasswordMutationVariables = Exact<{
  ChangePassword: ChangePassword;
}>;


export type ChangePasswordByOldPasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePasswordByOldPassword: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  ) }
);

export type CreateQuickUrlMutationVariables = Exact<{
  CreateQuickUrl: CreateQuickUrl;
}>;


export type CreateQuickUrlMutation = (
  { __typename?: 'Mutation' }
  & { createQuickUrl: (
    { __typename?: 'QuickRrl' }
    & Pick<QuickRrl, 'id' | 'name' | 'uri'>
  ) }
);

export type DeleteQuickUrlMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteQuickUrlMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuickUrl: (
    { __typename?: 'QuickRrl' }
    & Pick<QuickRrl, 'id' | 'name' | 'uri'>
  ) }
);

export type LoginMutationVariables = Exact<{
  LoginByAdmin: LoginByAdmin;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { loginByAdmin: (
    { __typename?: 'UserAccessToken' }
    & Pick<UserAccessToken, 'user_id' | 'teacher_id' | 'expires_in' | 'token'>
  ) }
);

export type QuickUrlsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickUrlsQuery = (
  { __typename?: 'Query' }
  & { quickUrls: Array<(
    { __typename?: 'QuickRrl' }
    & Pick<QuickRrl, 'id' | 'name' | 'uri'>
  )> }
);

export type UpdateQuickUrlMutationVariables = Exact<{
  UpdateQuickUrl: UpdateQuickUrl;
}>;


export type UpdateQuickUrlMutation = (
  { __typename?: 'Mutation' }
  & { updateQuickUrl: (
    { __typename?: 'QuickRrl' }
    & Pick<QuickRrl, 'id' | 'name' | 'uri'>
  ) }
);

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'mobile' | 'nickname' | 'avatar' | 'headimgurl'>
  ) }
);


export const ChangeAvatarDocument = gql`
    mutation changeAvatar($avatar: String!) {
  changeAvatar(avatar: $avatar) {
    id
    headimgurl
    avatar
  }
}
    `;
export type ChangeAvatarMutationFn = ApolloReactCommon.MutationFunction<ChangeAvatarMutation, ChangeAvatarMutationVariables>;
export type ChangeAvatarComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeAvatarMutation, ChangeAvatarMutationVariables>, 'mutation'>;

    export const ChangeAvatarComponent = (props: ChangeAvatarComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeAvatarMutation, ChangeAvatarMutationVariables> mutation={ChangeAvatarDocument} {...props} />
    );
    
export type ChangeAvatarProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeAvatarMutation, ChangeAvatarMutationVariables>
    } & TChildProps;
export function withChangeAvatar<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeAvatarMutation,
  ChangeAvatarMutationVariables,
  ChangeAvatarProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeAvatarMutation, ChangeAvatarMutationVariables, ChangeAvatarProps<TChildProps, TDataName>>(ChangeAvatarDocument, {
      alias: 'changeAvatar',
      ...operationOptions
    });
};
export type ChangeAvatarMutationResult = ApolloReactCommon.MutationResult<ChangeAvatarMutation>;
export type ChangeAvatarMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeAvatarMutation, ChangeAvatarMutationVariables>;
export const ChangePasswordByOldPasswordDocument = gql`
    mutation changePasswordByOldPassword($ChangePassword: ChangePassword!) {
  changePasswordByOldPassword(ChangePassword: $ChangePassword) {
    id
    nickname
  }
}
    `;
export type ChangePasswordByOldPasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordByOldPasswordMutation, ChangePasswordByOldPasswordMutationVariables>;
export type ChangePasswordByOldPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordByOldPasswordMutation, ChangePasswordByOldPasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordByOldPasswordComponent = (props: ChangePasswordByOldPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordByOldPasswordMutation, ChangePasswordByOldPasswordMutationVariables> mutation={ChangePasswordByOldPasswordDocument} {...props} />
    );
    
export type ChangePasswordByOldPasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangePasswordByOldPasswordMutation, ChangePasswordByOldPasswordMutationVariables>
    } & TChildProps;
export function withChangePasswordByOldPassword<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangePasswordByOldPasswordMutation,
  ChangePasswordByOldPasswordMutationVariables,
  ChangePasswordByOldPasswordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangePasswordByOldPasswordMutation, ChangePasswordByOldPasswordMutationVariables, ChangePasswordByOldPasswordProps<TChildProps, TDataName>>(ChangePasswordByOldPasswordDocument, {
      alias: 'changePasswordByOldPassword',
      ...operationOptions
    });
};
export type ChangePasswordByOldPasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordByOldPasswordMutation>;
export type ChangePasswordByOldPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordByOldPasswordMutation, ChangePasswordByOldPasswordMutationVariables>;
export const CreateQuickUrlDocument = gql`
    mutation createQuickUrl($CreateQuickUrl: CreateQuickUrl!) {
  createQuickUrl(CreateQuickUrl: $CreateQuickUrl) {
    id
    name
    uri
  }
}
    `;
export type CreateQuickUrlMutationFn = ApolloReactCommon.MutationFunction<CreateQuickUrlMutation, CreateQuickUrlMutationVariables>;
export type CreateQuickUrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateQuickUrlMutation, CreateQuickUrlMutationVariables>, 'mutation'>;

    export const CreateQuickUrlComponent = (props: CreateQuickUrlComponentProps) => (
      <ApolloReactComponents.Mutation<CreateQuickUrlMutation, CreateQuickUrlMutationVariables> mutation={CreateQuickUrlDocument} {...props} />
    );
    
export type CreateQuickUrlProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateQuickUrlMutation, CreateQuickUrlMutationVariables>
    } & TChildProps;
export function withCreateQuickUrl<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateQuickUrlMutation,
  CreateQuickUrlMutationVariables,
  CreateQuickUrlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateQuickUrlMutation, CreateQuickUrlMutationVariables, CreateQuickUrlProps<TChildProps, TDataName>>(CreateQuickUrlDocument, {
      alias: 'createQuickUrl',
      ...operationOptions
    });
};
export type CreateQuickUrlMutationResult = ApolloReactCommon.MutationResult<CreateQuickUrlMutation>;
export type CreateQuickUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuickUrlMutation, CreateQuickUrlMutationVariables>;
export const DeleteQuickUrlDocument = gql`
    mutation deleteQuickUrl($id: String!) {
  deleteQuickUrl(id: $id) {
    id
    name
    uri
  }
}
    `;
export type DeleteQuickUrlMutationFn = ApolloReactCommon.MutationFunction<DeleteQuickUrlMutation, DeleteQuickUrlMutationVariables>;
export type DeleteQuickUrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteQuickUrlMutation, DeleteQuickUrlMutationVariables>, 'mutation'>;

    export const DeleteQuickUrlComponent = (props: DeleteQuickUrlComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteQuickUrlMutation, DeleteQuickUrlMutationVariables> mutation={DeleteQuickUrlDocument} {...props} />
    );
    
export type DeleteQuickUrlProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteQuickUrlMutation, DeleteQuickUrlMutationVariables>
    } & TChildProps;
export function withDeleteQuickUrl<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteQuickUrlMutation,
  DeleteQuickUrlMutationVariables,
  DeleteQuickUrlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteQuickUrlMutation, DeleteQuickUrlMutationVariables, DeleteQuickUrlProps<TChildProps, TDataName>>(DeleteQuickUrlDocument, {
      alias: 'deleteQuickUrl',
      ...operationOptions
    });
};
export type DeleteQuickUrlMutationResult = ApolloReactCommon.MutationResult<DeleteQuickUrlMutation>;
export type DeleteQuickUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQuickUrlMutation, DeleteQuickUrlMutationVariables>;
export const LoginDocument = gql`
    mutation Login($LoginByAdmin: LoginByAdmin!) {
  loginByAdmin(LoginByAdmin: $LoginByAdmin) {
    user_id
    teacher_id
    expires_in
    token
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>
    } & TChildProps;
export function withLogin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps, TDataName>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const QuickUrlsDocument = gql`
    query quickUrls {
  quickUrls {
    id
    name
    uri
  }
}
    `;
export type QuickUrlsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuickUrlsQuery, QuickUrlsQueryVariables>, 'query'>;

    export const QuickUrlsComponent = (props: QuickUrlsComponentProps) => (
      <ApolloReactComponents.Query<QuickUrlsQuery, QuickUrlsQueryVariables> query={QuickUrlsDocument} {...props} />
    );
    
export type QuickUrlsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuickUrlsQuery, QuickUrlsQueryVariables>
    } & TChildProps;
export function withQuickUrls<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuickUrlsQuery,
  QuickUrlsQueryVariables,
  QuickUrlsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuickUrlsQuery, QuickUrlsQueryVariables, QuickUrlsProps<TChildProps, TDataName>>(QuickUrlsDocument, {
      alias: 'quickUrls',
      ...operationOptions
    });
};
export type QuickUrlsQueryResult = ApolloReactCommon.QueryResult<QuickUrlsQuery, QuickUrlsQueryVariables>;
export const UpdateQuickUrlDocument = gql`
    mutation updateQuickUrl($UpdateQuickUrl: UpdateQuickUrl!) {
  updateQuickUrl(UpdateQuickUrl: $UpdateQuickUrl) {
    id
    name
    uri
  }
}
    `;
export type UpdateQuickUrlMutationFn = ApolloReactCommon.MutationFunction<UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables>;
export type UpdateQuickUrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables>, 'mutation'>;

    export const UpdateQuickUrlComponent = (props: UpdateQuickUrlComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables> mutation={UpdateQuickUrlDocument} {...props} />
    );
    
export type UpdateQuickUrlProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables>
    } & TChildProps;
export function withUpdateQuickUrl<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateQuickUrlMutation,
  UpdateQuickUrlMutationVariables,
  UpdateQuickUrlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables, UpdateQuickUrlProps<TChildProps, TDataName>>(UpdateQuickUrlDocument, {
      alias: 'updateQuickUrl',
      ...operationOptions
    });
};
export type UpdateQuickUrlMutationResult = ApolloReactCommon.MutationResult<UpdateQuickUrlMutation>;
export type UpdateQuickUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuickUrlMutation, UpdateQuickUrlMutationVariables>;
export const ViewerDocument = gql`
    query viewer {
  viewer {
    id
    mobile
    nickname
    avatar
    headimgurl
  }
}
    `;
export type ViewerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerQuery, ViewerQueryVariables>, 'query'>;

    export const ViewerComponent = (props: ViewerComponentProps) => (
      <ApolloReactComponents.Query<ViewerQuery, ViewerQueryVariables> query={ViewerDocument} {...props} />
    );
    
export type ViewerProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerQuery, ViewerQueryVariables>
    } & TChildProps;
export function withViewer<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerQuery,
  ViewerQueryVariables,
  ViewerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerQuery, ViewerQueryVariables, ViewerProps<TChildProps, TDataName>>(ViewerDocument, {
      alias: 'viewer',
      ...operationOptions
    });
};
export type ViewerQueryResult = ApolloReactCommon.QueryResult<ViewerQuery, ViewerQueryVariables>;