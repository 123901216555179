import NoFoundPage from "../pages/404";
import AccoutMgmt from "../pages/AccoutMgmt";
import ConfigPage from "../pages/ConfigPage";
import Login from "../pages/Login";
import MainContent from "../pages/MainContent";

const routes = [
  { path: "/", name: "mainPage", component: MainContent, auth: true },
  { path: "/config", name: "config", component: ConfigPage, auth: true },
  { path: "/login", name: "login", component: Login },
  { path:'/accout', name: 'accoutMgmt', component: AccoutMgmt},
  { path: "/404", name: "404", component: NoFoundPage },
]

export default routes