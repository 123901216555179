import React from "react";
import styled from "styled-components";
import FormComponent from "./component/FormComponent";

export interface ConfigPageProps {
  
}
 
export interface ConfigPageState {
  
}
 
class ConfigPage extends React.Component<ConfigPageProps, ConfigPageState> {
  constructor(props: ConfigPageProps) {
    super(props);
    this.state = { };
  }
  render() { 
    return (
      <FormCotent>
        <h1>项目配置</h1>
        <FormComponent />
      </FormCotent>
    );
  }
}
 
export default ConfigPage;

const FormCotent = styled.div`
  width:100%;
  min-height:100%;
  padding: 24px 0 100px;
  /* overflow-y:scroll; */
  display:flex;
  flex-direction:column;
  align-items:center;
  background-color:#fff;
`